import { v4 } from 'uuid';
import { AuthenticatedHttp } from '../../http/axios';

export const uploadImage = async ({ productID, file }) => {
  const formData = new FormData();

  formData.append('file', file);

  return AuthenticatedHttp.post(`/gallery/upload/${productID}/`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }).then(({ data }) => ({
    id: data.id,
    preview: data.file,
  }));
};

export const removeImage = async ({ productID, imageID }) =>
  new Promise((resolve, reject) =>
    setTimeout(
      () =>
        resolve({
          id: v4(),
        }),
      2000
    )
  );
