import { Helmet } from 'react-helmet-async';
// @mui
import { Alert, AlertTitle, Button, Card, Tab } from '@mui/material';
// sections
import { useContext, useState } from 'react';
// utils
import { TabContext, TabList, TabPanel, Bu } from '@mui/lab';
import { Container, Stack } from '@mui/system';
import { useMutation } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router';
import { useLocation, useSearchParams } from 'react-router-dom';
import ProductWarning from '../../components/product-warning';
import Iconify from '../../components/iconify';
import { EditProductContext } from '../../contexts/EditProductContext';
import ProductClassesProvider from '../../contexts/ProductClassesContext';
import ProductLessonsProvider from '../../contexts/ProductLessonsContext';
import { ProductModulesContext } from '../../contexts/ProductModulesContext';
import ProductStudentsProvider from '../../contexts/ProductStudentsContext';
import ProductClassesPanel from '../../sections/@dashboard/product-classes/ProductClassesPanel';
import ProductCoursePanel from '../../sections/@dashboard/product-course/ProductCoursePanel';
import ProductCourseSettingsForm from '../../sections/@dashboard/product-course/ProductCourseSettingsForm';
import { CoursePreview } from '../../sections/@dashboard/product-members';
import ProductStudentsPanel from '../../sections/@dashboard/product-students/ProductStudentsPanel';
import { getMemberToken } from '../../services/members/token';

export default function ProductMembersArea() {
  const [searchParams] = useSearchParams();

  const { pathname } = useLocation();

  const [tabValue, setTabValue] = useState(searchParams.get('tab') || 'content');
  const [isOpenCoursePreview, setIsOpenCoursePreview] = useState(false);
  const [courseUrl, setCourseUrl] = useState('');

  const navigate = useNavigate();

  const { id: courseId } = useParams();

  const { product } = useContext(EditProductContext);

  const { isError } = useContext(ProductModulesContext);

  const { mutate, isLoading } = useMutation({
    mutationFn: getMemberToken,
    onSuccess(data) {
      const { accessToken } = data;

      const baseUrl = process.env.REACT_APP_MEMBER_BASE_URL || 'https://members.cakto.com.br';
      const url = `${baseUrl}/auth/redirect?accessToken=${accessToken}&course=${courseId}`;

      setIsOpenCoursePreview(true);
      setCourseUrl(url);
    },
  });

  const memberAreaTabClicks = localStorage.getItem('member-area-tab-blicks') || 1;

  const handleGoBack = () => {
    navigate(-+memberAreaTabClicks);
    localStorage.setItem('member-area-tab-blicks', 1);
  };

  if (product && product?.contentDelivery !== 'cakto') {
    return (
      <Container maxWidth="lg">
        <Alert severity="warning">
          <AlertTitle>Atenção</AlertTitle>A área de membros está disponível apenas para produtos com
          entrega de conteúdo através da plataforma Cakto.
        </Alert>
      </Container>
    );
  }

  return (
    <ProductLessonsProvider>
      <Helmet>
        <title> Área de membros </title>
      </Helmet>
      <Container
        sx={{
          maxWidth: '1280px !important',
        }}
      >
        <CoursePreview
          open={isOpenCoursePreview}
          onClose={() => setIsOpenCoursePreview(false)}
          courseUrl={courseUrl}
        />
        <Stack direction="row" justifyContent="space-between" mb={3}>
          <Button
            variant="text"
            startIcon={<Iconify icon="ion:arrow-back-outline" />}
            onClick={handleGoBack}
          >
            Voltar
          </Button>
          <Button
            variant="outlined"
            color="inherit"
            startIcon={<Iconify icon="eva:external-link-fill" />}
            onClick={mutate}
            disabled={isLoading || isError}
          >
            Pré-visualizar curso
          </Button>
        </Stack>
        <TabContext value={tabValue}>
          {product?.status === 'blocked' && (
            <ProductWarning
              title="Produto Bloqueado"
              message="Este produto encontra-se bloqueado. Contate o suporte para mais informações."
            />
          )}
          <Card>
            <TabList
              value={tabValue}
              onChange={(event, newTab) => {
                setTabValue(newTab);
                navigate(`${pathname}?tab=${newTab}`);
                localStorage.setItem('member-area-tab-blicks', +memberAreaTabClicks + 1);
              }}
              sx={{ p: 1, px: 3 }}
            >
              <Tab
                disabled={isError}
                value="content"
                label="Conteúdo"
                icon={<Iconify icon="eva:book-open-fill" />}
              />
              <Tab
                disabled={isError}
                value="students"
                label="Alunos"
                icon={<Iconify icon="eva:people-fill" />}
              />
              {/* <Tab value="classes" label="Turmas" icon={<Iconify icon="ic:baseline-class" />} />
                <Tab
                  value="settings"
                  label="Configurações"
                  icon={<Iconify icon="eva:settings-2-fill" />}
                /> */}
            </TabList>
          </Card>

          <TabPanel sx={{ p: 0, mt: 2 }} value="content">
            <ProductCoursePanel />
          </TabPanel>

          <TabPanel sx={{ p: 0, mt: 2 }} value="students">
            <ProductStudentsProvider>
              <ProductStudentsPanel />
            </ProductStudentsProvider>
          </TabPanel>

          <TabPanel sx={{ p: 0, mt: 2 }} value="classes">
            <ProductClassesProvider>
              <ProductClassesPanel />
            </ProductClassesProvider>
          </TabPanel>

          <TabPanel sx={{ p: 0, mt: 2 }} value="settings">
            <ProductCourseSettingsForm />
          </TabPanel>
        </TabContext>
      </Container>
    </ProductLessonsProvider>
  );
}
