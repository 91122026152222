import { useMutation, useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { createContext, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { saveCheckout } from '../services/checkout-builder';
import { getCheckout } from '../services/product-checkout';
import { FormScopes } from '../utils/form';

const CheckoutBuilderContext = createContext({
  device: 'desktop',
  setDevice: () => {},
  scope: FormScopes.INDEX,
  setScope: () => {},
  hovered: null,
  setHovered: () => {},
  update: () => {},
  updating: false,
  checkout: null,
  checkouts: [],
  loading: false,
});

const CheckoutBuilderProvider = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();

  const [device, setDevice] = useState('desktop');

  const [scope, setScope] = useState(FormScopes.INDEX);

  const [hovered, setHovered] = useState(null);

  const { id } = useParams();

  const { data: checkout, isLoading: loading } = useQuery({
    queryKey: ['checkout-builder', id],
    queryFn: () =>
      getCheckout({
        id,
      }),
    enabled: !!id,
    initialData: null,
  });

  const { mutateAsync: update, isLoading: updating } = useMutation({
    mutationFn: (data) => saveCheckout({ ...data, id }),
    onSuccess: (config) => {
      enqueueSnackbar('Checkout salvo com sucesso!', {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar('Erro ao salvar checkout!', {
        variant: 'error',
      });
    },
  });

  const value = useMemo(
    () => ({
      device,
      setDevice,
      scope,
      setScope,
      hovered,
      setHovered,
      update,
      updating,
      checkout,
      loading,
    }),
    [checkout, device, hovered, loading, scope, update, updating]
  );

  return (
    <CheckoutBuilderContext.Provider value={value}>{children}</CheckoutBuilderContext.Provider>
  );
};

CheckoutBuilderProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { CheckoutBuilderContext, CheckoutBuilderProvider };
