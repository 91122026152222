// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  dashboard: icon('ic_dashboard'),
  mySales: icon('ic_ecommerce'),
  collaborators: icon('ic_user'),
  financial: icon('ic_banking'),
  apps: icon('ic_external'),
  reports: icon('ic_file'),
  products: icon('ic_booking'),
  notifications: icon('ic_chat'),
  affiliates: icon('ic_user'),
  subscriptions: icon('ic_label'),
  coupons: icon('ic_coupon'),
};

const navConfig = [
  // Inicio
  // ----------------------------------------------------------------------
  {
    items: [
      { title: 'Dashboard', path: PATH_DASHBOARD.general.home, icon: ICONS.dashboard },
      {
        title: 'Produtos',
        path: `${PATH_DASHBOARD.general.products.root}?tab=products`,
        icon: ICONS.products,
      },
      {
        title: 'Minhas vendas',
        path: `${PATH_DASHBOARD.general.mySales}?tab=paid`,
        icon: ICONS.mySales,
      },
      /* {
        // TODO subscription disabled
        title: 'Assinaturas',
        path: `${PATH_DASHBOARD.general.subscriptions}?tab=active`,
        icon: ICONS.subscriptions,
      }, */
      // {
      //   title: 'Colaboradores',
      //   path: PATH_DASHBOARD.general.collaborators,
      //   icon: ICONS.collaborators,
      // },
      { title: 'Relatórios', path: PATH_DASHBOARD.general.reports.root, icon: ICONS.reports },
      {
        title: 'Afiliados',
        path: `${PATH_DASHBOARD.general.affiliates}?tab=active`,
        icon: ICONS.affiliates,
      },
      { title: 'Financeiro', path: PATH_DASHBOARD.general.financial, icon: ICONS.financial },
      { title: 'Apps', path: PATH_DASHBOARD.general.apps, icon: ICONS.apps },
      { title: 'Notificações', path: PATH_DASHBOARD.notifications.root, icon: ICONS.notifications },
      { title: 'Cupons', path: PATH_DASHBOARD.coupons.root, icon: ICONS.coupons },
    ],
  },
];

export default navConfig;
