import { AuthenticatedHttp } from '../../http/axios';
import { parsePaginationResponse } from '../../utils/api';

export const getAffiliates = async ({
  page,
  search,
  type,
  status,
  limit = 10,
  filter = {},
  url = '',
}) => {
  const params = {
    page,
    search,
    limit,
    status,
    type,
    url,
    ...(filter?.products?.length > 0 && {
      productPk: filter.products,
    }),
    ...(filter?.userEmail && {
      userEmail: filter.userEmail,
    }),
    ...(status?.length > 0 && {
      status,
    }),
  };

  return AuthenticatedHttp.get(`/affiliates/${url}`, { params }).then(parsePaginationResponse);
};

export const createAffiliateService = async ({ productPk, userEmail }) =>
  AuthenticatedHttp.post('/affiliates/', {
    productPk,
    userEmail,
  });

export const updateAffiliateService = async ({ id, info }) =>
  AuthenticatedHttp.put(`/affiliates/${id}/`, info);

export const acceptAffiliateService = async (ids) =>
  AuthenticatedHttp.post(`/affiliates/accept_many/`, {
    ids,
  });

export const blockAffiliateService = async (ids) =>
  AuthenticatedHttp.post(`/affiliates/block_many/`, {
    ids,
  });

export const unblockAffiliateService = async (ids) =>
  AuthenticatedHttp.post(`/affiliates/unblock_many/`, {
    ids,
  });

export const rejectAffiliateService = async (ids) =>
  AuthenticatedHttp.post(`/affiliates/reject_many/`);

export const deleteAffiliateService = async ({ id }) =>
  AuthenticatedHttp.delete(`/affiliates/${id}/`);
