export const parsePaginationResponse = ({ data: { count, next, previous, results, ...rest } }) => ({
  count,
  next,
  previous,
  results,
  ...rest,
});

export const parseResponseError = (error) => {
  console.log(error);
  const { status, data } = error.response;

  if (status === 400) {
    return data?.message || data?.detail || 'Erro ao processar requisição';
  }

  if (status === 401) {
    return 'Você não tem permissão para acessar este recurso';
  }

  if (status === 404) {
    return 'Recurso não encontrado';
  }

  if (status === 500) {
    return 'Erro interno do servidor';
  }

  return 'Erro ao processar requisição';
};
