import { useQuery } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { createContext, useMemo } from 'react';
import useTableFilter from '../hooks/useTableFilter';
import { getWithdrawals } from '../services/financial';

export const WithdrawalsContext = createContext({
  table: {},
  withdrawals: [],
  fetching: false,
  count: 0,
});

export const WithdrawalsStatus = {
  pending: {
    label: 'Pendente',
    color: 'warning',
  },
  approved: {
    label: 'Aprovado',
    color: 'success',
  },
  rejected: {
    label: 'Rejeitado',
    color: 'error',
  },
};

const WithdrawalsProvider = ({ children }) => {
  const table = useTableFilter({
    defaultCurrentPage: 1,
  });

  const {
    data: { results: withdrawals = [], count },
    isLoading: fetching,
  } = useQuery({
    queryKey: ['withdrawals', table.page],
    queryFn: () =>
      getWithdrawals({
        page: table.page,
      }),
    initialData: {
      results: [],
      count: 0,
    },
  });

  const value = useMemo(
    () => ({
      withdrawals,
      table,
      fetching,
      count,
    }),
    [withdrawals, table, fetching, count]
  );

  return <WithdrawalsContext.Provider value={value}>{children}</WithdrawalsContext.Provider>;
};

WithdrawalsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default WithdrawalsProvider;
