import * as datefns from 'date-fns';
import { v4 } from 'uuid';
import * as yup from 'yup';

export const CHECKOUT_BUILDER_DRAWER_WIDTH = 360;

export const CHECKOUT_BUILDER_DESKTOP_ROW_LAYOUTS = [[12], [8, 4], [4, 8], [4, 4, 4]];

export const CHECKOUT_BUILDER_MOBILE_ROW_LAYOUTS = [[12]];

export const CheckoutBuilderFontFamilies = ['Roboto', 'Open Sans'];

export const CheckoutBuilderDeviceTypes = {
  desktop: {
    id: 'desktop',
    name: 'Desktop',
    icon: 'ion:desktop-outline',
    layouts: CHECKOUT_BUILDER_DESKTOP_ROW_LAYOUTS,
  },
  mobile: {
    id: 'mobile',
    name: 'Mobile',
    icon: 'ion:phone-portrait-outline',
    layouts: CHECKOUT_BUILDER_MOBILE_ROW_LAYOUTS,
  },
};

export const CheckoutBuilderComponentTypes = {
  text: {
    id: 'text',
    type: 'text',
    name: 'Texto',
    icon: 'ion:text-outline',
    done: true,
    attributes: {
      backgroundColor: '#808080',
      borderColor: '#404040',
      text: '<p><span style="font-size: 64px; color: #ffffff;">Digite seu texto aqui</span></p>',
      color: '#ffffff',
      borderWidth: 1,
      borderRadius: 1,
    },
    schema: yup.object().shape({
      backgroundColor: yup.string().required('Obrigatório'),
      borderColor: yup.string().required('Obrigatório'),
      text: yup.string().required('Obrigatório'),
      borderWidth: yup.number().required('Obrigatório'),
      borderRadius: yup.number().required('Obrigatório'),
    }),
  },
  image: {
    id: 'image',
    type: 'image',
    name: 'Imagem',
    icon: 'bxs:image',
    done: true,
    attributes: {
      image: '',
      alignment: 'center',
      redirectUrl: '',
      width: null,
    },
    schema: yup.object().shape({
      image: yup.mixed().required('Obrigatório'),
      alignment: yup.string().required('Obrigatório'),
      redirectUrl: yup.string(),
      width: yup.number().required('Obrigatório'),
    }),
  },
  advantage: {
    id: 'advantage',
    type: 'advantage',
    name: 'Vantagens',
    icon: 'ion:checkmark-done',
    done: true,
    attributes: {
      title: 'Garantia de 7 dias',
      subtitle: 'Experimente sem riscos',
      icon: 'verified',
      primaryColor: '#0f7865',
      titleTextColor: '#000000',
      size: 'original',
      darkMode: false,
      vertical: false,
    },
    schema: yup.object().shape({
      icon: yup.string().required('Obrigatório'),
      primaryColor: yup.string().required('Obrigatório'),
      titleTextColor: yup.string().required('Obrigatório'),
      size: yup.string().required('Obrigatório'),
      darkMode: yup.boolean().required('Obrigatório'),
      vertical: yup.boolean().required('Obrigatório'),
    }),
  },
  seal: {
    id: 'seal',
    type: 'seal',
    name: 'Selo',
    icon: 'ion:ribbon',
    done: true,
    attributes: {
      type: 'one',
      title: 'Privacidade',
      subtitle: 'Garantida',
      primaryColor: '#4593f9',
      titleTextColor: '#fff',
      alignment: 'center',
      darkMode: false,
      width: 250,
    },
    schema: yup.object().shape({
      type: yup.string().required('Obrigatório'),
      title: yup.string().required('Obrigatório'),
      subtitle: yup.string().required('Obrigatório'),
      primaryColor: yup.string().required('Obrigatório'),
      titleTextColor: yup.string().required('Obrigatório'),
      alignment: yup.string().required('Obrigatório'),
      darkMode: yup.boolean().required('Obrigatório'),
      width: yup.number().required('Obrigatório'),
    }),
  },
  header: {
    id: 'header',
    type: 'header',
    name: 'Header',
    icon: 'uis:web-grid-alt',
    done: true,
    attributes: {
      backgroundType: 'image',
      backgroundColor: '#ffffff',
      backgroundImage: '',
      productImageType: 'same',
      productImage: '',
      productImageAlignment: 'left',
      titleFontSize: 24,
      titleTextColor: '#000000',
      titleText: 'Nome do seu produto',
      showSubtitle: false,
      subtitleFontSize: 14,
      subtitleTextColor: '#000000',
      subtitleText: 'Escreva um subtítulo aqui',
    },
    schema: yup.object().shape({
      backgroundType: yup.string().required('Obrigatório'),
      backgroundColor: yup.string().required('Obrigatório'),
      backgroundImage: yup.mixed().required('Obrigatório'),
      productImageType: yup.string().required('Obrigatório'),
      productImage: yup.mixed().required('Obrigatório'),
      productImageAlignment: yup.string().required('Obrigatório'),
      titleFontSize: yup.number().required('Obrigatório'),
      titleTextColor: yup.string().required('Obrigatório'),
      titleText: yup.string().required('Obrigatório'),
      showSubtitle: yup.boolean().required('Obrigatório'),
      subtitleFontSize: yup.number().required('Obrigatório'),
      subtitleTextColor: yup.string().required('Obrigatório'),
      subtitleText: yup.string().required('Obrigatório'),
    }),
  },
  list: {
    id: 'list',
    type: 'list',
    name: 'Lista',
    icon: 'majesticons:list-box',
    done: true,
    attributes: {
      backgroundColor: '#ffffff',
      textColor: '#000000',
      alignment: 'left',
      fontSize: 16,
      showTitle: false,
      title: 'Título da lista',
      style: 'check',
      iconColor: '#0f7865',
      items: [
        {
          id: v4(),
          text: 'Item 1',
        },
        {
          id: v4(),
          text: 'Item 2',
        },
        {
          id: v4(),
          text: 'Item 3',
        },
      ],
    },
    schema: yup.object().shape({
      backgroundColor: yup.string().required('Obrigatório'),
      textColor: yup.string().required('Obrigatório'),
      alignment: yup.string().required('Obrigatório'),
      fontSize: yup.number().required('Obrigatório'),
      showTitle: yup.boolean().required('Obrigatório'),
      title: yup.string().required('Obrigatório'),
      style: yup.string().required('Obrigatório'),
      iconColor: yup.string().required('Obrigatório'),
      items: yup
        .array()
        .of(
          yup.object().shape({
            id: yup.string().required('Obrigatório'),
            text: yup.string().required('Obrigatório'),
          })
        )
        .min(1)
        .required('Obrigatório'),
    }),
  },
  countdown: {
    id: 'countdown',
    type: 'countdown',
    name: 'Cronômetro',
    icon: 'icon-park-solid:timer',
    done: true,
    attributes: {
      backgroundColor: '#e55858ff',
      textColor: '#ffffff',
      activeText: 'Oferta por tempo limitado',
      finishedText: 'Oferta finalizada',
      fixedOnTop: false,
      type: 'time',
      time: '15:00',
      date: datefns.format(new Date(), 'yyyy-MM-dd'),
    },
    schema: yup.object().shape({
      backgroundColor: yup.string().required('Obrigatório'),
      textColor: yup.string().required('Obrigatório'),
      activeText: yup.string().required('Obrigatório'),
      finishedText: yup.string().required('Obrigatório'),
      fixedOnTop: yup.boolean().required('Obrigatório'),
      type: yup.string().required('Obrigatório'),
      time: yup.string().required('Obrigatório'),
      date: yup.string().required('Obrigatório'),
    }),
  },
  testimonial: {
    id: 'testimonial',
    type: 'testimonial',
    name: 'Depoimento',
    icon: 'material-symbols:reviews',
    done: true,
    attributes: {
      backgroundColor: '#ffffff',
      textColor: '#000000',
      avatar: null,
      author: 'John Doe',
      text: 'Digite seu depoimento aqui',
      rating: 5,
      horizontal: false,
    },
    schema: yup.object().shape({
      backgroundColor: yup.string().required('Obrigatório'),
      textColor: yup.string().required('Obrigatório'),
      avatar: yup.mixed().required('Obrigatório'),
      author: yup.string().required('Obrigatório'),
      text: yup.string().required('Obrigatório'),
      rating: yup.number().required('Obrigatório'),
      horizontal: yup.boolean().required('Obrigatório'),
    }),
  },
  video: {
    id: 'video',
    type: 'video',
    name: 'Vídeo',
    icon: 'majesticons:video',
    done: true,
    attributes: {
      url: '',
      alignment: 'center',
      width: 300,
      hideControls: false,
    },
    schema: yup.object().shape({
      url: yup.string().required('Obrigatório'),
      alignment: yup.string().required('Obrigatório'),
      width: yup.number().required('Obrigatório'),
      hideControls: yup.boolean().required('Obrigatório'),
    }),
  },
  facebook: {
    id: 'facebook',
    type: 'facebook',
    name: 'Facebook',
    icon: 'ic:baseline-facebook',
    done: true,
    attributes: {
      type: 'commentSection',
      url: '',
      size: 'original',
      count: 3,
      orderBy: 'relevant',
      tabs: ['timeline'],
      options: [],
    },
    schema: yup.object().shape({
      type: yup.string().required('Obrigatório'),
      url: yup.string().required('Obrigatório'),
      size: yup.string().required('Obrigatório'),
      tabs: yup.array().required('Obrigatório'),
      options: yup.array().required('Obrigatório'),
      count: yup.number().required('Obrigatório'),
      orderBy: yup.string().required('Obrigatório'),
    }),
  },
  map: {
    id: 'map',
    type: 'map',
    name: 'Mapa',
    icon: 'mdi:location',
    done: true,
    attributes: {
      address: '',
      alignment: 'center',
      width: 300,
    },
    schema: yup.object().shape({
      address: yup.string().required('Obrigatório'),
      alignment: yup.string().required('Obrigatório'),
      width: yup.number().required('Obrigatório'),
    }),
  },
  exitPopup: {
    id: 'exitPopup',
    type: 'exitPopup',
    name: 'Exit Popup',
    icon: 'fluent:form-multiple-28-filled',
    extra: true,
    done: true,
    devices: [CheckoutBuilderDeviceTypes.desktop.id, CheckoutBuilderDeviceTypes.mobile.id],
    attributes: {
      enabled: false,
      type: 'image',
      actionOnClick: 'offer',
      offer: null,
      title: 'Temos uma oferta especial para você!',
      description: 'Escreva algo aqui para convencer o cliente a não sair da página',
      actionLabel: 'Sim, eu quero!',
      image: null,
      video: null,
      url: null,
      backgroundButtonColor: '#0f7865',
      textButtonColor: '#ffffff',
    },
    schema: yup.object().shape({
      enabled: yup.boolean().required('Obrigatório'),
      type: yup.string().required('Obrigatório'),
      actionOnClick: yup.string().required('Obrigatório'),
      offer: yup.mixed().required('Obrigatório'),
      title: yup.string().required('Obrigatório'),
      description: yup.string().required('Obrigatório'),
      actionLabel: yup.string().required('Obrigatório'),
      image: yup.mixed().required('Obrigatório'),
      video: yup.string().url('Por favor, insira un vídeo válido').required('Obrigatório'),
      url: yup.string().required('Obrigatório'),
    }),
  },
  notification: {
    id: 'notification',
    type: 'notification',
    name: 'Notificação',
    icon: 'basil:notification-on-solid',
    extra: true,
    done: true,
    devices: [CheckoutBuilderDeviceTypes.desktop.id],
    attributes: {
      enabled: false,
      interestedLast24Hours: {
        enabled: false,
        value: 0,
      },
      interestedLastWeek: {
        enabled: false,
        value: 0,
      },
      interestedRightNow: {
        enabled: false,
        value: 0,
      },
      purchasedLast24Hours: {
        enabled: false,
        value: 0,
      },
      purchasedLastWeek: {
        enabled: false,
        value: 0,
      },
    },
    schema: yup.object().shape({
      enabled: yup.boolean().required('Obrigatório'),
      interestedLast24Hours: yup.object().shape({
        enabled: yup.boolean().required('Obrigatório'),
        value: yup.number().required('Obrigatório'),
      }),
      interestedLastWeek: yup.object().shape({
        enabled: yup.boolean().required('Obrigatório'),
        value: yup.number().required('Obrigatório'),
      }),
      interestedRightNow: yup.object().shape({
        enabled: yup.boolean().required('Obrigatório'),
        value: yup.number().required('Obrigatório'),
      }),
      purchasedLast24Hours: yup.object().shape({
        enabled: yup.boolean().required('Obrigatório'),
        value: yup.number().required('Obrigatório'),
      }),
      purchasedLastWeek: yup.object().shape({
        enabled: yup.boolean().required('Obrigatório'),
        value: yup.number().required('Obrigatório'),
      }),
    }),
  },
  chat: {
    id: 'chat',
    type: 'chat',
    name: 'Chat',
    icon: 'fluent:chat-24-filled',
    extra: true,
    done: true,
    devices: [CheckoutBuilderDeviceTypes.desktop.id, CheckoutBuilderDeviceTypes.mobile.id],
    attributes: {
      enabled: false,
      provider: 'whatsapp',
      accountId: '',
    },
    schema: yup.object().shape({
      enabled: yup.boolean().required('Obrigatório'),
      provider: yup.string().required('Obrigatório'),
      accountId: yup.string().required('Obrigatório'),
    }),
  },
  row: {
    id: 'row',
    type: 'row',
    name: 'Linha',
    icon: 'carbon:row',
    attributes: {},
  },
  checkout: {
    id: 'checkout',
    type: 'checkout',
    name: 'Checkout',
    icon: 'icon-park-solid:shopping',
    attributes: {},
    config: {
      settings: {
        background: {
          color: '#ffffff',
        },
      },
    },
  },
};
