import { createContext, useMemo } from 'react';

import PropTypes from 'prop-types';

export const CoursesContext = createContext({
  courses: [],
  isFetchingCourses: false,
});

const CoursesProvider = ({ children }) => {
  const value = useMemo(
    () => ({
      courses: [],
      isFetchingCourses: false,
    }),
    []
  );

  return <CoursesContext.Provider value={value}>{children}</CoursesContext.Provider>;
};

CoursesProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CoursesProvider;
