import { localStorageAvailable } from '@mui/x-data-grid/utils/utils';
import { useQuery, useMutation } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { createContext, useCallback, useEffect, useMemo, useReducer } from 'react';
import {
  login as loginService,
  me,
  recoveryChangePasswordService,
  recoverySendCodeService,
  register as registerService,
  socialLogin,
} from '../services/auth';
import { updateAmbientService } from '../services/user';
import { clearSession, isValidToken, jwtDecode, setSession } from './utils';

const initialState = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
};

const reducer = (state, action) => {
  if (action.type === 'INITIAL') {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGIN') {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }
  if (action.type === 'REGISTER') {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
    };
  }
  if (action.type === 'UPDATE_USER') {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  return state;
};

export const AuthContext = createContext(null);

AuthProvider.propTypes = {
  children: PropTypes.node,
};

export function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const storageAvailable = localStorageAvailable();

  const { refetch } = useQuery({
    queryKey: ['me'],
    queryFn: async () => {
      try {
        return me();
      } catch (error) {
        return null;
      }
    },
    enabled: state.isAuthenticated,
    refetchInterval: 120000,
  });

  const initialize = useCallback(
    async ({ accessToken, refreshToken }) => {
      const isValid = await isValidToken(accessToken);

      try {
        if (!accessToken || !isValid) {
          throw new Error('Credenciais inválidas');
        }

        setSession({
          accessToken,
          refreshToken,
        });

        const { data: user } = await refetch();

        if (!user) {
          throw new Error('Credenciais inválidas');
        }

        if (!user.id) {
          user.id = jwtDecode(accessToken).user_id;
        }

        dispatch({
          type: 'INITIAL',
          payload: {
            isAuthenticated: true,
            user,
          },
        });
      } catch (error) {
        dispatch({
          type: 'INITIAL',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
        throw error;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [storageAvailable]
  );

  useEffect(() => {
    const accessToken = storageAvailable ? localStorage.getItem('accessToken') : '';
    const refreshToken = storageAvailable ? localStorage.getItem('refreshToken') : '';
    initialize({ accessToken, refreshToken });
  }, [initialize, storageAvailable]);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const baseUrl = window.location.origin + window.location.pathname;
  const accessToken = urlParams.get('accessToken');
  const refreshToken = urlParams.get('refreshToken');

  useQuery({
    queryKey: [{ baseUrl, accessToken, refreshToken }],
    queryFn: async () => {
      if (accessToken && refreshToken) {
        try {
          await initialize({ accessToken, refreshToken });
        } catch (error) {
          window.location.href = '/auth/login';
        }
      }

      return true;
    },
  });

  // LOGIN
  const login = useCallback(
    async (email, password, recaptchaToken) =>
      loginService({ email, password, recaptchaToken }).then(initialize),
    [initialize]
  );

  const recoverySendCode = useCallback(
    async ({ email, recaptchaToken }) => recoverySendCodeService({ email, recaptchaToken }),
    []
  );

  const recoveryChangePassword = useCallback(
    async ({ token, password }) => recoveryChangePasswordService({ token, password }),
    []
  );

  const updateAmbient = useCallback(
    async ({ viewPageAs }) => {
      updateAmbientService({ viewPageAs });

      dispatch({
        type: 'INITIAL',
        payload: {
          isAuthenticated: true,
          user: {
            ...state.user,
            viewPageAs,
          },
        },
      });
    },
    [state]
  );

  const loginWithSocial = useCallback(
    async ({ accessToken: googleAccessToken, provider = 'google' }) =>
      socialLogin({
        access_token: googleAccessToken,
        provider,
      }).then(initialize),
    [initialize]
  );

  const { mutateAsync: register } = useMutation({
    mutationFn: registerService,
    async onSuccess(data) {
      await initialize(data);
    },
    // retry: 1,
  });

  // LOGOUT
  const logout = useCallback(() => {
    clearSession();
    dispatch({
      type: 'LOGOUT',
    });
  }, []);

  const setUser = useCallback((user) => {
    dispatch({
      type: 'UPDATE_USER',
      payload: {
        user,
      },
    });
  }, []);

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      user: state.user,
      method: 'jwt',
      login,
      loginWithSocial,
      register,
      logout,
      setUser,
      recoverySendCode,
      recoveryChangePassword,
      updateAmbient,
      initialize,
    }),
    [
      state.isAuthenticated,
      state.isInitialized,
      state.user,
      login,
      loginWithSocial,
      logout,
      register,
      setUser,
      recoverySendCode,
      recoveryChangePassword,
      updateAmbient,
      initialize,
    ]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
