import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { createContext, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import {
  deleteProductImageService,
  deleteProductService,
  getProductCategoriesService,
  getProductService,
  getAffiliateProductService,
  updateProductImageService,
  updateProductService,
  updateAffiliatePixelsService,
} from '../services/products';

export const EditProductContext = createContext({
  product: {},
  isFetchingProduct: false,
  updateProduct: () => {},
  isUpdatingProduct: false,
  categories: [],
  isFetchingCategories: false,
  deleteProduct: () => {},
  isDeletingProduct: false,
  cancelSubscriptions: () => {},
  isCancelingSubscriptions: false,
  updatePixels: () => {},
  isUpdatingFixels: false,
  differentOffers: false,
  setDifferentOffers: () => {},
  firstError: null,
  setFirstError: () => {},
});

export const ProductType = {
  subscription: {
    value: 'subscription',
    label: 'Assinatura',
  },
  unique: {
    value: 'unique',
    label: 'Pagamento único',
  },
};

const EditProductProvider = ({ children }) => {
  const queryClient = useQueryClient();

  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const { id, short_id } = useParams();

  const { pathname } = useLocation();

  const isAffiliate = pathname.includes('affiliates');

  const [differentOffers, setDifferentOffers] = useState(false);
  const [firstError, setFirstError] = useState();

  const { data: product, isLoading: isFetchingProduct } = useQuery(
    ['product', { id, short_id }],
    () => (isAffiliate ? getAffiliateProductService({ short_id }) : getProductService({ id })),
    {
      enabled: !!id,
      initialData: undefined,
      select(data) {
        return {
          ...data,
          offers: data.offers?.map((offer) => ({
            ...offer,
            offerId: offer.id,
          })),
          bumps: data.bumps?.sort((a, b) => a.position - b.position),
        };
      },
    }
  );

  const { data: categories, isFetching: isFetchingCategories } = useQuery(
    ['categories'],
    () => getProductCategoriesService(),
    {
      initialData: [],
    }
  );

  const { mutateAsync: updatePixels, isLoading: isUpdatingFixels } = useMutation({
    mutationFn: async (tracking_pixels) => updateAffiliatePixelsService({ id, tracking_pixels }),
    onSuccess(data) {
      const productKey = ['product', { id, short_id }];

      const previous = queryClient.getQueryData(productKey);
      const newData = {
        ...previous,
        tracking_pixels: data,
      };

      queryClient.setQueryData(productKey, newData);
      enqueueSnackbar('Dados atualizados com sucesso', { variant: 'success' });
    },
    onError(error) {
      const fbErrorMessage = error?.response?.data?.facebook_pixels?.filter(
        (pixel) => Object.keys(pixel).length > 0
      );
      const errorMessage =
        fbErrorMessage[0]?.non_field_errors[0] ||
        error.message ||
        'Erro ao atualizar dados do produto';
      enqueueSnackbar(errorMessage, { variant: 'error' });
    },
  });

  const { mutateAsync: updateProduct, isLoading: isUpdatingProduct } = useMutation({
    mutationFn: async ({ image, ...data }) => {
      if (!differentOffers) {
        data.offers = [];
      }

      if (typeof image === 'string') {
        fetch(image)
          .then((response) => response.blob())
          .then(async (blob) => {
            const defaultImage = new File([blob], 'defaultProductCover.svg', {
              type: 'image/svg+xml',
            });

            await updateProductImageService({ id, image: defaultImage });
          });
      }
      if (image instanceof File) await updateProductImageService({ id, image });
      if (product?.image && !image) await deleteProductImageService({ id });

      const allData = {
        ...data,
        bumps: data?.bumps.map((bump, index) => ({
          ...bump,
          position: index,
        })),
        offers: data.offers?.map((offer) => {
          const { offerId, ...rest } = offer;

          return {
            ...rest,
            id: offerId || offer.id,
          };
        }),
      };

      return updateProductService({ ...allData, id });
    },
    onSuccess: async () => {
      enqueueSnackbar('Dados atualizados com sucesso', { variant: 'success' });
      queryClient.invalidateQueries('products');
    },
    onError: (error) => {
      queryClient.invalidateQueries('products');
      enqueueSnackbar(error.message ?? 'Erro ao atualizar dados do produto', { variant: 'error' });
    },
  });

  const { mutateAsync: deleteProduct, isLoading: isDeletingProduct } = useMutation(
    () => deleteProductService({ id }),
    {
      onSuccess: () => {
        enqueueSnackbar('Produto deletado com sucesso', { variant: 'success' });
        navigate('/dashboard/products');
      },
    },
    {
      onError: () => {
        enqueueSnackbar('Erro ao deletar produto', { variant: 'error' });
      },
    }
  );

  const { mutateAsync: cancelSubscriptions, isLoading: isCancelingSubscriptions } = useMutation(
    () => new Promise((resolve) => setTimeout(resolve, 1000)),
    {
      onSuccess: () => {
        enqueueSnackbar('Inscrições canceladas com sucesso', { variant: 'success' });
      },
    },
    {
      onError: () => {
        enqueueSnackbar('Erro ao cancelar inscrições', { variant: 'error' });
      },
    }
  );

  const value = useMemo(
    () => ({
      product,
      isFetchingProduct,
      updateProduct,
      categories,
      isFetchingCategories,
      isUpdatingProduct,
      deleteProduct,
      isDeletingProduct,
      cancelSubscriptions,
      isCancelingSubscriptions,
      updatePixels,
      isUpdatingFixels,
      differentOffers,
      setDifferentOffers,
      firstError,
      setFirstError,
    }),
    [
      product,
      isFetchingProduct,
      updateProduct,
      categories,
      isFetchingCategories,
      isUpdatingProduct,
      deleteProduct,
      isDeletingProduct,
      cancelSubscriptions,
      isCancelingSubscriptions,
      updatePixels,
      isUpdatingFixels,
      differentOffers,
      setDifferentOffers,
      firstError,
      setFirstError,
    ]
  );

  return <EditProductContext.Provider value={value}>{children}</EditProductContext.Provider>;
};

EditProductProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default EditProductProvider;
